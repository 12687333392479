import React from "react";
import "./App.css";
import maintenanceImage from "./maintenance.png"; // Make sure to add the image to the project

function App() {
  return (
    <div className="App">
      <div className="maintenance-container">
        <img src={maintenanceImage} alt="BentoHub Under Maintenance" className="maintenance-image" />
        <h1>🚧 BentoHub is Under Maintenance 🚧</h1>
        <p>We’re currently working hard to bring you a better experience. Please check back soon!</p>
      </div>
    </div>
  );
}

export default App;